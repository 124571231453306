import styled from 'styled-components';
import { theme } from '../../../../theme/theme-vars';

const HeroStyles = styled.section`
  .hero-wrapper {
    background-color: ${theme.colours.red};
    display: flex;

    @media (max-width: ${theme.components.phoneWidthMax}) {
      flex-direction: column;
    }

    .hero-cta-wrapper {
      max-width: 50vw;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: ${theme.components.phoneWidthMax}) {
        max-width: 100vw;
      }

      h1 {
        color: ${theme.colours.pink};
        text-align: left !important;

        span {
          color: #fff !important;
        }
      }
      button {
        max-width: 200px;

        @media (max-width: ${theme.components.phoneWidthMax}) {
          max-width: 100%;
        }
      }
      .logos-wrapper {
        width: 180px;
        margin-top: 20px;

        @media (max-width: ${theme.components.phoneWidthMax}) {
          margin-left: auto;
          margin-right: auto;
        }

        img {
          width: 100%;
        }
      }
    }

    .hero-aside {
      @media (max-width: ${theme.components.phoneWidthMax}) {
        margin-top: 20px;
      }

      .hero-img {
        height: 90vh;
        width: 50vw;
        text-align: center;

        @media (max-width: ${theme.components.phoneWidthMax}) {
          min-width: 100%;
          margin: 0 auto;
          height: auto;
        }

        @media (min-width: ${theme.components.desktopWidthMin}) {
          max-width: 40vw;
        }

        img {
          height: 100%;

          @media (max-width: ${theme.components.phoneWidthMax}) {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .intro-p--white {
      color: #fff;
      text-align: left;
    }
    .primary-btn--applehero {
      border: 1px solid ${theme.colours.red};
      color: ${theme.colours.red};

      &:hover {
        background-color: ${theme.colours.red};
        border: 1px solid #fff;
        color: #fff;
      }
    }
  }
`;
export default HeroStyles;
