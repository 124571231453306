import React, { useState, useEffect } from 'react';
// Assets
import logosLockup from '../../../../../static/images/anytime-logos-lockup.png';
import screens from '../../../../../static/images/anytime-applepay.png';
// Styles
import HeroStyles from './styles';

const Hero = ({ userId }) => {
  const [userIdState, setUserIdState] = useState(null);
  const anyTimeLandingURL = userIdState
    ? `/anytime/?userId=${userIdState}`
    : '/anytime';
  useEffect(() => {
    setUserIdState(userId);
  }, [userId]);
  return (
    <HeroStyles className="container container--red">
      <div className="section-wrapper hero-wrapper">
        <div className="hero-cta-wrapper">
          <h1>
            Pay easily and securely with your Nimble AnyTime Card using
            {' '}
            <span>Apple Pay</span>
          </h1>
          <p className="intro-p intro-p--white">
            Using Apple Pay is simple, and it works with the devices you use
            every day. Your card information is secure because it isn’t stored
            on your device or shared when you pay. Paying in stores, in selected
            apps, and on participating websites has never been easier, safer or
            more private.
          </p>
          <a href={anyTimeLandingURL}>
            <button
              className="primary-btn primary-btn--white primary-btn--applehero"
              aria-label="Apply Now"
            >
              Apply Now
            </button>
          </a>
          <div className="logos-wrapper">
            <img src={logosLockup} alt="Nimble and Apple logos lockup" />
          </div>
        </div>
        <div className="hero-aside">
          <div className="hero-img">
            <img src={screens} alt="app screenshots" />
          </div>
        </div>
      </div>
    </HeroStyles>
  );
};
export default Hero;
