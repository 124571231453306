import React from 'react';
// Styles
import HowtoStyles from './styles';

const Howto = () => (
  <HowtoStyles className="container container--warmgrey">
    <div className="section-wrapper howto-wrapper">
      <h3>How to use Apple Pay</h3>
      <div className="frames-wrapper">
        <div className="frame">
          <iframe
            src="https://www.youtube.com/embed/35mdHemHWZk"
            title="How to pay using Touch ID"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
          <h4>How to pay using Touch ID</h4>
        </div>
        <div className="frame">
          <iframe
            src="https://www.youtube.com/embed/G7Sqhg0RQtM"
            title="How to pay with Face ID"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
          <h4>How to pay with Face ID</h4>
        </div>
      </div>
    </div>
  </HowtoStyles>
);

export default Howto;
