import styled from 'styled-components';

const ApplePayOverideStyles = styled.section`
  h3 {
    font-family: SharpGroteskBook, sans-serif;
    font-size: 25px;
    line-height: 1.3;
    font-weight: bold;
  }
  .static-grid {
    max-width: 900px;
    margin: 0 auto;
  }
  .MuiTypography-body1 {
    p {
      color: #646464 !important;
    }
  }
`;
export default ApplePayOverideStyles;
