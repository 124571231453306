import React from 'react';
// Styles
import SetupStyles from './styles';
// Assets
import step1Screen from '../../../../../static/images/applepay-step1-screen.png';
import step2Screen from '../../../../../static/images/applepay-step2-screen.png';
import step3Screen from '../../../../../static/images/applepay-step3-screen.png';

const Setup = () => (
  <SetupStyles className="container container--grey">
    <div className="section-wrapper setup-wrapper">
      <h3>Add your card to Apple Pay. Setup is easy</h3>
      <section className="screens-wrapper">
        <div className="ta-center step-wrapper">
          <div className="screen-img-wrapper">
            <img src={step1Screen} alt="apple pay step 1 screen" />
          </div>
          <div className="step">
            <div className="step-counter-wrapper">
              <div className="count-icon">1</div>
              <h4>Step 1</h4>
            </div>
            <p className="p-grey">
              In the Nimble AnyTime App, tap Add to Apple Wallet
            </p>
          </div>
        </div>
        <div className="ta-center step-wrapper">
          <div className="screen-img-wrapper">
            <img src={step2Screen} alt="apple pay step 1 screen" />
          </div>
          <div className="step">
            <div className="step-counter-wrapper">
              <div className="count-icon">
                <div>2</div>
              </div>
              <h4>Step 2</h4>
            </div>
            <p className="p-grey">Follow the prompts</p>
          </div>
        </div>
        <div className="ta-center step-wrapper">
          <div className="screen-img-wrapper">
            <img src={step3Screen} alt="apple pay step 1 screen" />
          </div>
          <div className="step">
            <div className="step-counter-wrapper">
              <div className="count-icon">3</div>
              <h4>Step 3</h4>
            </div>
            <p className="p-grey">
              Your card has now been added to Apple Wallet and you're ready to
              Tap and Pay easily and securely with Apple Pay
            </p>
          </div>
        </div>
      </section>
    </div>
  </SetupStyles>
);
export default Setup;
