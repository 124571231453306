import React, { useEffect } from 'react';
// Styles
import GlobalStyles from './styles';
// Components
import Hero from './hero';
import Information from './information';
import Setup from './setup';
import Howto from './howto';
import Faqs from './faqs';

const ApplePayLanding = () => {
  let userId = undefined;
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    userId = decodeURIComponent(urlParams.get('userId'));
  }, []);

  const faqsData = [
    {
      question: 'Where can I use Apple Pay?',
      answer:
        '<p>You can use Apple Pay to make payments at any merchant where contactless payments are accepted. Simply look for the contactless symbol. You may also use Apple Pay in-app and on the web with Safari when you see the “Buy with Apple Pay” button or Apple Pay as a payment option.</p>'
    },
    {
      question: 'Which cards are eligible for Apple Pay?',
      answer:
        '<p>Currently, only the Nimble AnyTime Card is available on Apple Pay.</p>'
    },
    {
      question: 'What is my Device Account Number, and where can I find it?',
      answer:
        '<p>All cards stored in Apple Pay are allocated a Device Account Number. This unique number ensures your payment information isn’t stored on your device or shared with the merchant when making a payment. You can find your Device Account Number by opening the Wallet app, selecting your card and tapping the ... symbol.</p>'
    },
    {
      question: 'Where can I find out more information about Apple Pay?',
      answer:
        "<p>To find out more information on how to make secure payments and set up Apple Pay with your Card, please visit our Apple Pay webpage or <a href='https://www.apple.com/au/apple-pay/'>apple.com/au/apple-pay</a>.</p>"
    },
    {
      question: 'What Apple devices are compatible with Apple Pay?',
      answer:
        "<p>Apple Pay works with iPhone 6 and later in stores, apps, and websites in Safari; with Apple Watch in stores and apps (requires iPhone 6 or later); with iPad Pro, iPad (5th generation), iPad Air 2, and iPad mini 3 and later in apps and websites in Safari; and with Mac (2012 or later) in Safari with an Apple Pay-enabled iPhone or Apple Watch. For a list of compatible Apple Pay devices, <a href='https://support.apple.com/en-us/HT208531'>see here</a>.</p>"
    }
  ];

  return (
    <GlobalStyles>
      <Hero userId={userId} />
      <Information />
      <Setup />
      <Howto />
      <Faqs data={faqsData} />
    </GlobalStyles>
  );
};
export default ApplePayLanding;
