import styled from 'styled-components';
import { theme } from '../../theme/theme-vars';

const FaqComponentStyles = styled.div`
  .wrapper {
    padding: 45px 30px 50px 30px;

    /* @media (max-width: ${theme.components.phoneWidthMax}) {
      padding: 0;
    } */

    h2 {
      font-family: ${theme.font.nimbleHeadingFont};
      font-weight: normal;
      font-size: 55px;
      text-align: center;
      margin-bottom: -5px;
    }

    .frequently-asked-questions-sections {
      display: flex;
      max-width: 1350px;
      margin: 0 auto;
      flex-wrap: wrap;

      @media (max-width: ${theme.components.phoneWidthMax}) {
        display: block;
      }

      .frequently-asked-questions-section {
        flex-basis: 0;
        flex-grow: 1;
        padding: 25px 25px;
        min-width: 50%;
        max-width: 50%;

        &:only-child {
          max-width: 100%;
        }

        @media (max-width: ${theme.components.phoneWidthMax}) {
          max-width: 100%;
          margin-bottom: 24px;
          padding: 0;
        }

        h3 {
          font-family: ${theme.font.nimbleHeadingFont};
          font-size: 40px;
          margin: 30px 0 40px;
        }

        .frequently-asked-question {
          padding-right: 30px;
          padding-bottom: 27px;
          position: relative;

          &.expanded {
            .question {
              .question-button-plus {
                visibility: hidden;
              }

              .question-button-minus {
                visibility: visible;
              }
            }

            .answer {
              color: ${theme.colours.black};
              max-height: 200vh;
            }
          }
          &-section {
            display: flex !important;
            flex-wrap: wrap !important;
            -webkit-box-pack: justify !important;
            justify-content: space-between !important;
            max-width: 1350px !important;
            margin: 0 auto !important;
          }
        }
      }
    }
  }

  .question {
    font-family: ${theme.font.nimbleFont};
    font-weight: 800;
    color: ${theme.colours.black};
    font-weight: bold;
    cursor: pointer;
    line-height: 1.3;
    font-size: 20px;

    @media (max-width: ${theme.components.phoneWidthMax}) {
      font-size: 19px;
    }

    .question-button-plus,
    .question-button-minus {
      position: absolute;
      top: 0;
      right: 0;

      i {
        font-weight: bold;
      }
    }

    .question-button-minus {
      visibility: hidden;
    }
  }

  .answer {
    color: ${theme.colours.black};
    max-height: 0;
    overflow: hidden;
    transition-duration: 0.4s;

    p {
      font-size: 13px;
      line-height: 1.7;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  .MuiIconButton-root {
    color: ${theme.colours.red};
  }
  .MuiExpansionPanelDetails-root {
    padding: 0 24px;
  }
  .MuiTypography-body1 p {
    margin: 0;
  }
  .MuiExpansionPanelSummary-root {
    padding: none !important;
  }
  .static-grid {
    display: flex;
    @media (max-width: ${theme.components.phoneWidthMax}) {
      flex-direction: column;
    }
    .static-question-section {
      @media (max-width: ${theme.components.phoneWidthMax}) {
        margin-bottom: 0 !important;
      }
    }
  }
`;
export default FaqComponentStyles;
