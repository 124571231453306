import React from 'react';
// Styles
import InformationStyles from './styles';
// Assets
import contactlessIcon from '../../../../../static/images/applepay-contactless.png';
import screen from '../../../../../static/images/applepay-information-screen.png';

const Information = () => (
  <InformationStyles className="container container--white">
    <div className="section-wrapper information-wrapper">
      <div className="screen-wrapper">
        <img src={screen} alt="nimble credit card on device" />
      </div>
      <div className="content-wrapper">
        <h3>Nimble AnyTime with Apple Pay.</h3>
        <h3>The safer, more private way to pay.</h3>
        <p className="p-grey">
          When you make a purchase, Apple Pay uses a device-specific number
          and a unique transaction code. Your full card number is never stored
          on your device or on Apple servers, and it can’t be shared with
          merchants. Apple Pay doesn’t keep transaction information that can
          be tied back to you, ensuring all purchases are private.
        </p>
        <p className="p-grey hint">
          Use Apple Pay wherever you see this symbol
        </p>
        <div className="icon-wrapper">
          <img src={contactlessIcon} alt="contactless" />
        </div>
      </div>
    </div>
  </InformationStyles>
);
export default Information;
