import styled from 'styled-components';
import { theme } from '../../../theme/theme-vars';

const GlobalStyles = styled.div`
  .container {
    display: flex;
    justify-content: center;
  }
  .container--red {
    background-color: ${theme.colours.red};
  }
  .container--white {
    background-color: ${theme.colours.white};
  }
  .container--grey {
    background-color: #e6e6e6;
  }
  .container--warmgrey {
    background-color: #f6f6f6;
  }
  h1 {
    font-size: 72px !important;
    margin-bottom: 25px !important;
    text-transform: inherit;

    @media (max-width: ${theme.components.phoneWidthMax}) {
      font-size: 48px !important;
      line-height: 1.2 !important;
    }
  }
  h2 {
    font-size: 42px;
    margin-bottom: 40px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-style: normal !important;
  }
  h5 {
    @media (max-width: ${theme.components.phoneWidthMax}) {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  h3 {
    color: #000 !important;
  }
  h4 {
    color: #000 !important;
  }
  p {
    font-size: 16px !important;
    line-height: 1.7;

    &.p-grey {
      color: #646464;
    }
  }
  small {
    color: #595959;
    font-size: 14px;
  }
  .primary-btn {
    border: none;
    padding: 15px 30px;
    font-family: ${theme.font.nimbleFont};
    font-weight: bold;
    font-size: 20px;
    border-radius: 5px;

    @media (max-width: ${theme.components.phoneWidthMax}) {
      width: 100%;
    }

    &--green {
      background-color: ${theme.colours.green};
      border: 2px ${theme.colours.green} solid;
      color: #fff;
      cursor: pointer;
      transition: background-color 100ms linear;
      transition: color 100ms linear;
      &:hover {
        background-color: #fff;
        color: ${theme.colours.green};
      }
    }

    &--white {
      background-color: ${theme.colours.white};
      border: 2px ${theme.colours.green} solid;
      color: ${theme.colours.green};
      cursor: pointer;
      transition: background-color 100ms linear;
      transition: color 100ms linear;
      &:hover {
        background-color: ${theme.colours.green};
        color: #fff;
        border: 2px ${theme.colours.white} solid;
      }
    }

    &--red {
      background-color: ${theme.colours.red};
      border: 2px ${theme.colours.red} solid;
      color: ${theme.colours.white};
      cursor: pointer;
      transition: background-color 100ms linear;
      transition: color 100ms linear;
      &:hover {
        background-color: ${theme.colours.white};
        color: ${theme.colours.red};
      }
    }
  }
  .btn-wrapper--center {
    text-align: center;
  }
  .intro-p {
    font-size: 16px;
    line-height: 1.7;
    margin-bottom: 25px;
    margin-top: 0px;

    @media (max-width: ${theme.components.phoneWidthMax}) {
      font-size: 15px;
      margin-bottom: 35px;
    }
  }
  ul {
    li {
      list-style: none;
      align-items: center;
      color: #595959;
      margin-bottom: 20px;
      padding-left: 0;
      margin-left: 0;

      @media (max-width: ${theme.components.phoneWidthMax}) {
        text-align: left;
      }

      p {
        font-weight: bold;
        line-height: 1.6;
        font-size: 17px;
        padding-left: 20px !important;
      }
    }
  }
  .point {
    @media (max-width: ${theme.components.phoneWidthMax}) {
      flex-direction: row !important;
    }
  }
  .section-wrapper {
    padding: 65px 30px;
    max-width: 1440px;
  }
  .ta-center {
    text-align: center;
  }
  .p-inherit {
    position: inherit !important;
  }
`;
export default GlobalStyles;
