import styled from 'styled-components';

const AccordianElementOverideStyles = styled.div`
  .MuiExpansionPanel-root {
    position: inherit !important;
  }
  .MuiExpansionPanelSummary-root {
    padding: 0px !important;
  }
  .MuiExpansionPanelDetails-root {
    padding-left: 0px !important;
    padding-right: 24px !important;
  }
`;
export default AccordianElementOverideStyles;
