import styled from 'styled-components';
import { theme } from '../../../../theme/theme-vars';

const HowtoStyles = styled.section`
  .howto-wrapper {
    background-color: #f6f6f6;
    padding-bottom: 60px !important;

    @media (max-width: ${theme.components.phoneWidthMax}) {
      padding: 65px 0px;
      width: 90vw;
    }

    h3 {
      text-align: center;
    }

    .frames-wrapper {
      display: flex;
      width: 95vw;
      max-width: 1440px;
      margin: 30px auto 60px auto;

      @media (max-width: ${theme.components.phoneWidthMax}) {
        flex-direction: column;
        width: 100%;
        margin: 30px auto 0 auto;
      }
      .frame {
        margin-right: 40px;
        height: 381px;
        width: 675px;

        @media (max-width: ${theme.components.phoneWidthMax}) {
          margin-right: 0;
          margin-bottom: 80px;
          height: 170px;
          width: 100%;
        }
        @media (max-width: 1300px) {
          height: 290px;
        }

        &:last-child {
          margin-right: 0;
        }

        iframe {
          width: 100%;
          height: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
`;
export default HowtoStyles;
