import styled from 'styled-components';
import { theme } from '../../../../theme/theme-vars';

const SetupStyles = styled.section`
  .setup-wrapper {
    background-color: #e6e6e6;

    h3 {
      text-align: center;
    }

    .screens-wrapper {
      display: flex;
      margin-top: 60px;

      @media (max-width: ${theme.components.phoneWidthMax}) {
        flex-direction: column;
        margin-top: 20px;
      }

      .step-wrapper {
        padding: 0 60px;

        @media (max-width: 970px) {
          padding: 0 30px;
        }

        @media (max-width: ${theme.components.phoneWidthMax}) {
          flex-direction: column-reverse;
          display: flex;
          padding: 0;
        }

        .screen-img-wrapper {
          margin-right: auto;
          margin-left: auto;

          img {
            height: 600px;
            width: auto;

            @media (max-width: 1200px) {
              height: 470px;
            }
            @media (max-width: 970px) {
              height: 360px;
            }
          }
        }

        .step-counter-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 40px;

          .count-icon {
            background-color: ${theme.colours.red};
            color: #fff;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            margin-right: 15px;
          }
        }
      }
    }
    .step {
      max-width: 320px;
      @media (max-width: 970px) and (min-width: ${theme.components
    .phoneWidthMax}) {
        max-width: 400px;
      }
      @media (max-width: ${theme.components.phoneWidthMax}) {
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
`;
export default SetupStyles;
