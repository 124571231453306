import React from 'react';
// Styles
// Material UI Imports
import { makeStyles, StylesProvider } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ApplePayOverideStyles from './styles';
import FaqComponentStyles from '../../../../components/faq/styles';
import AccordianElementOverideStyles from '../../../../elements/accordian/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Faqs = props => {
  const classes = useStyles();

  return (
    <StylesProvider injectFirst>
      <ApplePayOverideStyles className="p-inherit">
        <FaqComponentStyles className="component componentType_componentFaq ">
          <div className="clearfix component-faq component-background_White wrapper">
            <div className="component-faq-content">
              <h3 className="h2-style ta-center">Apple Pay FAQ's</h3>
              <div className="static-grid">
                <div className="frequently-asked-questions-sections">
                  <div className="frequently-asked-questions-section static-question-section">
                    <AccordianElementOverideStyles>
                      <div className={classes.root}>
                        {props.data.map((section, index) => (
                          <ExpansionPanel key={index}>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography
                                className={classes.heading}
                                component="div"
                              >
                                <div className="question">
                                  <span>{section.question}</span>
                                </div>
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography component="div">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: section.answer,
                                  }}
                                />
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        ))}
                      </div>
                    </AccordianElementOverideStyles>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FaqComponentStyles>
      </ApplePayOverideStyles>
    </StylesProvider>
  );
};
export default Faqs;
