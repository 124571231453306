import styled from 'styled-components';
// import { theme } from "../../../theme/theme-vars"

const InformationStyles = styled.section`
  .information-wrapper {
    background-color: #fff;
    display: flex;
    padding-bottom: 0 !important;
    width: 100%;

    .screen-wrapper {
      max-height: 80vh;
      width: 40vw;
      text-align: left;
      padding-left: 30px;
      margin-right: 50px;

      @media (max-width: 980px) {
        display: none;
      }

      img {
        height: 100%;
      }
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 65px;
      padding-right: 5%;
      width: 50vw;

      @media (max-width: 980px) {
        width: 100%;
        padding-right: 0;
      }
      @media (max-width: 1100px) {
        padding-left: 5%;
        padding-right: 0;
      }

      h3 {
        color: #000;
      }

      .icon-wrapper {
        width: 80px;
        img {
          width: 100%;
        }
      }

      .hint {
        margin-top: 0;
        margin-bottom: 30px;
      }
    }
  }
`;
export default InformationStyles;
